import React from 'react'

const ServiceSuspensionNotice = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className="bg-white shadow-xl rounded-2xl p-8 max-w-md text-center">
                <a
                    href="https://mrretailer.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/mr-logo.png"
                        alt="Company Logo"
                        className="w-70 h-30 mx-auto mb-4 cursor-pointer"
                    />
                </a>
                <h1 className="text-2xl font-bold text-red-600 mb-2">
                    Service Temporarily Suspended
                </h1>
                <p className="text-gray-700 mb-4">
                    Due to continuous pending bills, your services have been
                    suspended. Please clear your dues at your earliest
                    convenience to resume services.
                </p>
                <div className="text-gray-800">
                    <p className="font-semibold">
                        For assistance, contact Salman:
                    </p>
                    <p>📞 +92 304 2603604</p>
                    <p>✉️ salman@mrretailer.com</p>
                </div>
            </div>
        </div>
    )
}

export default ServiceSuspensionNotice
